import React from "react";

const HistoryAlarmContainer = Loader.loadBusinessComponent(
  "MonitorHistoryAlarm",
  "HistoryAlarmContainer"
);

const searchDataInit = {
  startTime: undefined,
  endTime: undefined,
  timeType: undefined,
  alarmOperationType: undefined,
  taskIds: undefined,
  installationSites: undefined,
  noInstallationSites: undefined,
  libIds: [],
  sort: ["captureTime|desc"],
  captureUids: "",
  offset: 0,
  limit: 40,
  alarmTypes:["3"]
};

const searchList = [
  "AlarmTimeTypeSelect",
  "AlarmSiteScreening",
  "AlarmTimeRadio"
]

const EventHistoryAlarm = () => (
  <HistoryAlarmContainer 
    libType={3}
    moduleName='eventHistoryNotify'
    detailModuleName='eventDetail'
    cardType='ForeignCard'
    taskType='101503'
    searchDataInit={searchDataInit}
    searchList={searchList}
    cardStyle={{
      itemWidth: 174,
      itemHeight: 344,
      pdWidth: 120,
    }}
    showSearchInput={false}
  />
)

export default EventHistoryAlarm;
